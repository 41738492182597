import React, { useState, useEffect } from 'react';
import { Typography, Container, TablePagination, Link, Card, List, ListItem, CardActionArea, CardContent, MenuItem, IconButton, CardMedia, FormControl, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Fab, TextField, Alert, Snackbar, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CircleIcon from '@mui/icons-material/Circle';
import 'react-quill/dist/quill.snow.css';
import { userList } from '../services/UserService';
import { AddExp, ExpDetailslist, ExpPubDetailslist } from '../services/Experience';
import '../../public/styles/client.css';
import { FormatDT, TPA, modCard } from '../common/Common';
import MyCon from './MyCon';

export default function Experiences() {
  const [currentScreen, setCurrentScreen] = useState('');
  const [expList, setExpList] = useState([]);
  const [userData, setUserData] = useState([]);
  const userRole = sessionStorage.getItem('uRole') || null;
  const userId = sessionStorage.getItem('userId');
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setpage] = useState(0);
  const rowsPerPage = 3 * (isMobile ? 1 : 3);

  const fetchFileData = async () => {

    if (userRole) {
      try {
        let resp = await userList();
        setUserData(resp.result);
      } catch (error) {
        console.log(error)
      }
      try {
        let resp = await ExpDetailslist();
        const filteredList = resp.result.filter(x => (x.visibility == 'Members' &&
          x.experience_status == 'Published' && !x.archive) || (x.visibility == 'Public' && x.experience_status == 'Published' && !x.archive)); /*  x.experience_createdby == userId */
        setExpList(filteredList.reverse())
        // setExpList(resp.result.filter(x => (x.visibility == 'Members' &&
        //   x.experience_createdby == userId && x.experience_status == 'Published') || (x.visibility == 'Public' && x.experience_status == 'Published')))
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        let resp = await ExpPubDetailslist();
        setUserData(resp.userList);
        const filteredList = resp.result.filter(x => x.visibility == 'Public' && x.experience_status == 'Published' && !x.archive);
        setExpList(filteredList.reverse())
        // setExpList(resp.result.filter(x => x.visibility == 'Public' && x.experience_status == 'Published'))
      } catch (error) {
        console.log(error)
      }
    }
  };
  useEffect(() => {
    fetchFileData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUserData = userData && userData.filter(user => {
    return user.full_name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const filteredExpList = expList.filter(expData => {
    const user = userData.find(user => user._id === expData.experience_createdby);
    const postedDate = FormatDT(expData.created_at).toLowerCase();
    const searchQueryLower = searchQuery.toLowerCase();
    const matchesExpData = Object.values(expData).some(value => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false;
    });
    const matchesPostedDate = postedDate.includes(searchQueryLower);
    const matchesUserData = filteredUserData.some(user => user._id === expData.experience_createdby);

    return matchesExpData || matchesUserData || matchesPostedDate;
  });

  const ExpList = ({ expdatalist }) => {
    const [expanded, setExpanded] = useState(false);
    const base64Decoded = atob(expdatalist.experience_summary);
    const decodedHTML = decodeURIComponent(base64Decoded);
    const [fullContent, setFullContent] = useState('');
    const [slicedContent, setSlicedContent] = useState('');

    useEffect(() => {
      const content = { __html: decodedHTML };
      if (content.__html.length > 200) {
        setFullContent(content.__html);
        setSlicedContent(content.__html.slice(0, 200));
      } else {
        setFullContent(content.__html);
        setSlicedContent(content.__html);
      }
    }, [decodedHTML]);


    const getUserExpObj = (id) => {
      let obj = userData.filter(x => x._id == id)[0];
      return obj;
    }

    const handleClick = (e) => {
      e.preventDefault();
      setTimeout(() => {
        setExpanded(true);
      }, 0);
    };

    return (
      <Grid item xs={12} sm={4}>
        <Card onClick={handleClick} sx={modCard}>
          <Typography variant="h6">{expdatalist.experience_title.length > 30 ? expdatalist.experience_title.slice(0, 30) + '...' : expdatalist.experience_title}</Typography>
          <Typography sx={{ color: 'GrayText' }}>Posted by <b>{getUserExpObj(expdatalist.experience_createdby).full_name}</b></Typography>
          <Typography sx={{ color: 'GrayText' }}>Posted on <b>{FormatDT(expdatalist.created_at)}</b></Typography>
        </Card>
        <Dialog fullWidth maxWidth='lg' open={expanded}  >
          <DialogTitle style={{ padding: 0, marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => setExpanded(false)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} />
              </IconButton>
            </Box>
            <Box sx={{ paddingTop: '40px', margin: '10px' }}>
              <Grid container spacing={2}>
                <Grid item sm={9}>
                  <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>
                    {expdatalist.experience_title}
                  </Typography>
                </Grid>
                <Grid item sm={3} style={{ textAlign: 'right' }}>
                  <Typography sx={{ color: 'GrayText' }}>{getUserExpObj(expdatalist.experience_createdby).full_name} | {FormatDT(expdatalist?.created_at)}</Typography>
                  {/* <Typography component='p' variant='body1' color="text.secondary" sx={{ textTransform: 'none' }}>
                    Posted by-&nbsp;{getUserExpObj(expdatalist.experience_createdby).full_name}
                  </Typography> */}
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Card>
              <Typography className="ql-editor" dangerouslySetInnerHTML={{ __html: fullContent }}></Typography>
            </Card>
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };



  return (
    <>
      {!currentScreen && (
        <Box sx={{ p: 2 }}>
          {userRole ?
            <>
              <Card sx={{ minHeight: '75vh' }}>
                {expList.length > 0 &&
                  <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                    <Typography align="left" sx={{ flex: 1 }}>
                      Wish there was something you could tell your loved one? Write a message to them <Link style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={() => setCurrentScreen('expList')}>here.</Link>.
                    </Typography>
                    <FormControl sx={{ width: '130px' }}>
                      <TextField
                        label="Search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        variant="outlined"
                        size="small"
                      />
                    </FormControl>
                    <TablePagination count={filteredExpList.length} page={page} rowsPerPage={rowsPerPage} ActionsComponent={TPA} rowsPerPageOptions={[]}
                      sx={{ border: 'none', '.MuiTablePagination-displayedRows': { display: 'none' } }} onPageChange={(e, p) => setpage(p)} />
                  </Box >}

                {filteredExpList.length > 0 ? <>
                  <Grid container spacing={2}>
                    {filteredExpList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, i) => (
                      <ExpList key={i} expdatalist={data} />
                    ))}</Grid></>
                  : <Grid item xs={12}><Typography variant='h6' sx={{ mb: 0, textAlign: 'center' }}>No upcoming posts</Typography></Grid>}
              </Card>
            </>
            : <Card sx={{ minHeight: '75vh' }}>
              {expList.length > 0 &&
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                  <FormControl sx={{ width: '130px' }}>
                    <TextField
                      label="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      variant="outlined"
                      size="small"
                    />
                  </FormControl>
                  <TablePagination count={filteredExpList.length} page={page} rowsPerPage={rowsPerPage} ActionsComponent={TPA} rowsPerPageOptions={[]}
                    sx={{ border: 'none', '.MuiTablePagination-displayedRows': { display: 'none' } }} onPageChange={(e, p) => setpage(p)} />
                </Box >}
              {filteredExpList.length > 0 ? <>
                <Grid container spacing={2}>
                  {filteredExpList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, i) => (
                    <ExpList key={i} expdatalist={data} />
                  ))}</Grid></>
                : <Grid item xs={12}><Typography variant='h6' sx={{ mb: 0, textAlign: 'center' }} >No upcoming posts</Typography></Grid>}
            </Card>}
        </Box >
      )
      } {
        currentScreen &&
        <MyCon />
      }
    </>
  )
}
