import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import {
    Grid, Paper, CircularProgress, Table, TableContainer, TableHead, TableRow, TableCell, TableBody,
    TableFooter, TablePagination, Select, MenuItem, FormControl, Typography, Card, Button, Fab, TextField,
    useMediaQuery, Toolbar, Tab, Tabs, Container, Dialog, DialogTitle, DialogContent, DialogActions, IconButton
} from '@mui/material';
import { Add, Close, Edit, Archive, Unarchive, Info } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import { userList } from '../../services/UserService';
import { ListMedia, AddMedia, EditMedia, UpdateMedia, ArchiveMedia, UnarchiveMedia } from '../../services/MediaService';
import { StyledAlert, tabThemeAdmin } from '../TabsCore';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { trClose, TPA, FormatDT, pBtn, nBtn, adminTH, PerChk } from '../../common/Common';

const mObj = {
    _id: '',
    media_title: '',
    media_summary: '',
    media_status: '',
    media_createdby: '',
    media_updatedby: '',
    remarks: '',
    created_at: '',
    updated_at: ''
};

export default function MediaManagement() {
    const quill = useRef();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const userId = sessionStorage.getItem('userId');
    const [isLoading, setIsLoading] = useState(false);
    const [ti, setti] = useState(PerChk('MEDIA_LIST') ? '1' : '0');
    const [mediaObj, setmediaObj] = useState(mObj);
    const [viewObj, setviewObj] = useState(mObj);
    const [mediaList, setmediaList] = useState([]);
    const [uList, setuList] = useState([]);
    const [addAlert, setaddAlert] = useState(null);
    const [editAlert, seteditAlert] = useState(null);
    const [updateAlert, setupdateAlert] = useState(null);
    const [archAlert, setarchAlert] = useState(null);
    const [unarchAlert, setunarchAlert] = useState(null);
    const [addModal, setaddModal] = useState(false);
    const [editModal, seteditModal] = useState(false);
    const [mediaModal, setmediaModal] = useState(false);
    const [rjtModal, setrjtModal] = useState(false);
    const [archModal, setarchModal] = useState(null);
    const [unarchModal, setunarchModal] = useState(null);
    const [editDecode, seteditDecode] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [searchFilter, setsearchFilter] = useState('');
    const [rjtRmk, setrjtRmk] = useState('');
    const [page, setPage] = useState(0);
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
    ];
    const mediaListF = mediaList.filter((m) => {
        const formattedDateString = FormatDT(m?.created_at);
        const createdByUser = uList.find((u) => u._id === m?.media_createdby)?.full_name.toLowerCase();
        return (
            (statusFilter === 'All' || m?.media_status === statusFilter) &&
            (m?.media_title.toLowerCase().includes(searchFilter.toLowerCase()) ||
                formattedDateString.toLowerCase().includes(searchFilter.toLowerCase()) ||
                (createdByUser && createdByUser.includes(searchFilter.toLowerCase())))
        );
    }); const mediaListP = mediaListF.slice(page * 10, page * 10 + 10);
    const addDisabled = !mediaObj.media_title || !mediaObj.media_summary || isLoading;

    useEffect(() => { FetchMedia() }, [])

    const FetchMedia = async () => {
        try {
            const resp1 = await ListMedia(); if (resp1) {
                setmediaList(resp1.result.reverse())
            }
            const resp2 = await userList(); if (resp2) {
                setuList(resp2.result.filter(user => user.status === 'Active'))
            }
        }
        catch (error) { console.error() }
    }

    const MediaAdd = async () => {
        setIsLoading(true);
        try {
            let addObj = {
                media_title: mediaObj.media_title,
                media_summary: btoa(encodeURIComponent(mediaObj.media_summary)),
                media_status: 'Pending',
                media_createdby: userId
            }
            const resp = await AddMedia(addObj); if (resp) {
                FetchMedia(); setaddModal(false); setmediaObj(mObj);
                setaddAlert('success'); setTimeout(() => { setaddAlert(null) }, 3000);
            }
        }
        catch (error) {
            console.log('Error--', error); setaddAlert('error');
            setTimeout(() => { setaddAlert(null) }, 3000);
        }
        setIsLoading(false);
    }

    const MediaEdit = async (id) => {
        setIsLoading(true);
        try {
            let editObj = {
                media_title: mediaObj.media_title,
                media_summary: btoa(encodeURIComponent(editDecode)),
                media_status: 'Pending',
                media_updatedby: userId
            }
            const resp = await EditMedia(id, editObj); if (resp) {
                FetchMedia(); seteditModal(false); setmediaObj(mObj);
                seteditAlert('success'); setTimeout(() => { seteditAlert(null) }, 3000);
            }
        } catch (error) {
            console.log('Error--', error); seteditAlert('error');
            setTimeout(() => { seteditAlert(null) }, 3000);
        }
        setIsLoading(false);
    }

    const MediaUpdate = async (id, st) => {
        setIsLoading(true);
        try {
            let updateObj = {
                media_status: st,
                remarks: st === 'Rejected' ? rjtRmk : ''
            }
            const resp = await UpdateMedia(id, updateObj); if (resp) {
                FetchMedia(); setmediaModal(false); setmediaObj(mObj); setrjtModal(false);
                setupdateAlert('success'); setrjtRmk(''); setTimeout(() => { setupdateAlert(null) }, 3000);
            }
        } catch (error) {
            console.log('Error--', error); setupdateAlert('error');
            setrjtRmk('');
            setTimeout(() => { setupdateAlert(null) }, 3000);
        }
        setIsLoading(false);
    }

    const MediaArchive = async (id) => {
        setIsLoading(true);
        try {
            const resp = await ArchiveMedia(id); if (resp) {
                FetchMedia(); setarchAlert('success'); setarchModal(null);
                setTimeout(() => { setarchAlert(null) }, 3000);
            }
        } catch (error) {
            console.log('Error--', error); setarchAlert('error');
            setTimeout(() => { setarchAlert(null) }, 3000);
        }
        setIsLoading(false);
    }

    const MediaUnarchive = async (id) => {
        try {
            const resp = await UnarchiveMedia(id); if (resp) {
                FetchMedia(); setunarchAlert('success'); setunarchModal(null);
                setTimeout(() => { setunarchAlert(null) }, 3000);
            }
        } catch (error) {
            console.log('Error--', error); setunarchAlert('error');
            setTimeout(() => { setunarchAlert(null) }, 3000);
        }
    }


    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                ],
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        []
    )

    return (<>
        <Fab hidden={PerChk('ADD_MEDIA')} title='Add new media' onClick={() => setaddModal(true)} sx={{ position: 'fixed', bottom: 40, right: 20 }}><Add sx={{ color: '#fff' }} /></Fab>
        {addAlert && <StyledAlert severity={addAlert}>{addAlert === 'success' ? 'Media added' : 'Failed to add media'}</StyledAlert>}
        {editAlert && <StyledAlert severity={editAlert}>{editAlert === 'success' ? 'Changes saved' : 'Changes failed to save'}</StyledAlert>}
        {updateAlert && <StyledAlert severity={updateAlert}>{updateAlert === 'success' ? 'Media status updated' : 'Failed to update media status'}</StyledAlert>}
        {archAlert && <StyledAlert severity={archAlert}>{archAlert === 'success' ? 'Media archived' : 'Failed to archive media'}</StyledAlert>}
        {unarchAlert && <StyledAlert severity={unarchAlert}>{unarchAlert === 'success' ? 'Media unarchived' : 'Failed to unarchive media'}</StyledAlert>}

        <Toolbar><ThemeProvider theme={tabThemeAdmin}><Tabs value={ti} onChange={(e, v) => { setti(v); setsearchFilter(''); setStatusFilter('All'); setPage(0); }}>
            <Tab value={'0'} label='Media List' hidden={PerChk('MEDIA_LIST')} />
            <Tab value={'1'} label='Approval' hidden={PerChk('MEDIA_APPROVALLIST')} />
        </Tabs></ThemeProvider></Toolbar>

        <Container><Grid container>
            {((ti === '0' && mediaList.length > 0) || (ti === '1' && mediaList.length > 0)) && <Grid item xs={12}
                sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'flex-end', alignItems: isMobile ? 'flex-end' : null, gap: '10px', mb: 2 }}>
                {ti === '0' && <FormControl sx={{ width: '125px' }}><Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} size='small'>
                    <MenuItem value='All'>All</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Published">Published</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                </Select></FormControl>}
                <FormControl sx={{ width: '130px' }}><TextField value={searchFilter} size='small' onChange={(e) => setsearchFilter(e.target.value)} label='Search' /></FormControl>
            </Grid>}

            <Grid item xs={12}>{
                (ti === '0' && mediaListP.length > 0) ?
                    <Paper hidden={PerChk('MEDIA_LIST')} sx={{ width: '100%', overflow: 'hidden' }}><TableContainer sx={{ maxHeight: 440 }}><Table>
                        <TableHead sx={adminTH}><TableRow>
                            <TableCell sx={{ color: '#fff' }}>Title</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Posted by</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Posted on</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Status</TableCell>
                            <TableCell />
                        </TableRow></TableHead>

                        <TableBody>{ti === '0' && mediaListP.map((m, i) => (<TableRow key={i} onClick={() => { setviewObj(m); setmediaModal(true); }}
                            sx={{ '&:hover': { cursor: 'pointer', bgcolor: '#ddd' } }}>
                            <TableCell>{m?.media_title}</TableCell>
                            <TableCell>{uList.find((u) => u._id === m?.media_createdby)?.full_name}</TableCell>
                            <TableCell>{FormatDT(m?.created_at)}</TableCell>
                            <TableCell sx={{
                                color: m?.media_status === 'Published' ? 'green' :
                                    m?.media_status === 'Rejected' ? 'red' :
                                        m?.media_status === 'Pending' ? 'darkblue' :
                                            'black'
                            }}>{m?.media_status}</TableCell>
                            <TableCell onClick={(e) => e.stopPropagation()} align='right'>
                                <IconButton hidden={PerChk('EDIT_MEDIA')} title='Edit media' onClick={(e) => { setmediaObj(m); seteditDecode(decodeURIComponent(atob(m?.media_summary))); seteditModal(true); }}><Edit sx={{ color: '#b5651d' }} /></IconButton>
                                {m?.archive ? <IconButton hidden={PerChk('UNARCHIVE_MEDIA')} title='Unarchive media' onClick={() => setunarchModal(m?._id)} sx={{ color: '#0c0' }}><Unarchive /></IconButton>
                                    : <IconButton hidden={PerChk('ARCHIVE_MEDIA')} title='Archive media' disabled={m?.media_status !== 'Published'} onClick={() => setarchModal(m?._id)} sx={{ color: '#c00' }}><Archive /></IconButton>}
                            </TableCell>
                        </TableRow>))}</TableBody>

                        <TableFooter><TableRow>
                            <TablePagination count={mediaListF.length} page={page} rowsPerPage={10} sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]} ActionsComponent={TPA} />
                        </TableRow></TableFooter>
                    </Table></TableContainer></Paper>
                    : (ti === '1' && mediaListP.filter((m) => m.media_status === 'Pending').length > 0) ?
                        <Paper hidden={PerChk('MEDIA_APPROVALLIST')} sx={{ width: '100%', overflow: 'hidden' }}><TableContainer sx={{ maxHeight: 440 }}><Table>
                            <TableHead sx={adminTH}><TableRow>
                                <TableCell sx={{ color: '#fff' }}>Title</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Posted by</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Posted on</TableCell>
                            </TableRow></TableHead>

                            <TableBody>{mediaListP.filter((m) => m?.media_status === 'Pending').map((m, i) => (<TableRow key={i} onClick={() => { setrjtModal(false); setviewObj(m); setmediaModal(true); }}
                                sx={{ '&:hover': { cursor: 'pointer', bgcolor: '#ddd' } }}>
                                <TableCell>{m?.media_title}</TableCell>
                                <TableCell>{uList.find((u) => u._id === m?.media_createdby)?.full_name}</TableCell>
                                <TableCell>{FormatDT(m?.created_at)}</TableCell>
                            </TableRow>))}</TableBody>

                            <TableFooter><TableRow>
                                <TablePagination count={mediaListF.length} page={page} rowsPerPage={10} sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                    onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]} ActionsComponent={TPA} />
                            </TableRow></TableFooter>
                        </Table></TableContainer></Paper>
                        :
                        <Typography variant='h6' sx={{ textAlign: 'center' }}>{(searchFilter || statusFilter !== 'All') ?
                            'No results for your search' : 'No records'}</Typography>
            }</Grid>
        </Grid></Container>

        <Dialog open={archModal}>
            <Close sx={trClose} onClick={() => setarchModal(null)} disabled={isLoading} />
            <DialogTitle sx={{ mb: '5px', borderBottom: '1px solid #b5651d' }}><Typography variant='h6'>Confirmation</Typography></DialogTitle>
            <DialogContent><Typography sx={{ mb: '20px' }}>Are you sure you want to archive this media?</Typography></DialogContent>
            <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '5px' }}>
                <Button sx={nBtn} onClick={() => MediaArchive(archModal)} disabled={isLoading}>Archive</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={unarchModal}>
            <Close sx={trClose} onClick={() => setunarchModal(null)} disabled={isLoading} />
            <DialogTitle sx={{ mb: '5px', borderBottom: '1px solid #b5651d' }}><Typography variant='h6'>Confirmation</Typography></DialogTitle>
            <DialogContent><Typography sx={{ mb: '20px' }}>Are you sure you want to unarchive this media?</Typography></DialogContent>
            <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '5px' }}>
                <Button sx={pBtn} onClick={() => MediaUnarchive(unarchModal)} disabled={isLoading}>Unarchive</Button>
            </DialogActions>
        </Dialog>

        <Dialog fullWidth open={addModal}>
            <Close sx={trClose} onClick={() => { setaddModal(false); setmediaObj(mObj); }} />

            <DialogTitle sx={{ mt: '10px', mb: '5px', borderBottom: '1px solid #b5651d' }}>
                <Typography variant='h6'>Add New Media</Typography></DialogTitle>

            <DialogContent><Card sx={{ gap: '10px' }}>
                <TextField label="Title" value={mediaObj.media_title} sx={{ mb: 1 }}
                    onChange={(e) => setmediaObj({ ...mediaObj, media_title: e.target.value })} />

                <ReactQuill
                    style={{ overflow: 'auto' }}
                    ref={(el) => (quill.current = el)}
                    className="ql-container ql-snow"
                    value={mediaObj.media_summary}
                    formats={formats}
                    modules={modules}
                    onChange={(content) => setmediaObj({ ...mediaObj, media_summary: content })} />
                <small><Info /> <b>The size of each image shouldn't exceed 256 kB</b></small>
            </Card></DialogContent>

            <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 4 }}>
                <Button disabled={addDisabled || isLoading} onClick={MediaAdd} sx={pBtn}>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog fullWidth open={editModal}>
            <DialogTitle sx={{ mt: '10px', mb: '5px', borderBottom: '1px solid #b5651d' }}>
                <Typography variant='h6'>Edit Media</Typography></DialogTitle>

            <DialogContent><Card sx={{ gap: '10px' }}>
                <TextField label="Title" value={mediaObj.media_title} onChange={(e) => setmediaObj({ ...mediaObj, media_title: e.target.value })} />
                {mediaObj.remarks && <TextField label="Rejection remarks" value={mediaObj.remarks} disabled />}
                <ReactQuill
                    style={{ overflow: 'auto' }}
                    ref={(el) => (quill.current = el)}
                    className="ql-container ql-snow"
                    value={editDecode}
                    formats={formats}
                    modules={modules}
                    onChange={(content) => seteditDecode(content)}
                />
                <small><Info /> <b>The size of each image shouldn't exceed 256 kB</b></small>
            </Card></DialogContent>

            <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 2, gap: '5px' }}>
                <Button sx={nBtn} onClick={() => { seteditModal(false); setmediaObj(mObj); }} disabled={isLoading}>Close</Button>
                <Button sx={pBtn} onClick={() => MediaEdit(mediaObj._id)} disabled={isLoading}>{isLoading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </DialogActions>
        </Dialog>

        <Dialog fullWidth={rjtModal ? false : true} open={mediaModal} >{rjtModal ? <>
            <Close sx={trClose} onClick={() => { setrjtModal(false); setrjtRmk('') }} disabled={isLoading} />

            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', mt: '10px', mb: '5px', borderBottom: '1px solid #b5651d' }}>
                <Typography variant='h6'>Rejecting '{viewObj?.media_title}'</Typography>
                <Typography sx={{ color: 'GrayText' }}>By <b>{uList.find((u) => u._id === viewObj?.media_createdby)?.full_name}</b> on <b>{FormatDT(viewObj?.created_at)}</b></Typography>
            </DialogTitle>

            <DialogContent>
                <small>Enter remarks for rejection</small>
                <TextField value={rjtRmk} onChange={(e) => setrjtRmk(e.target.value)} />
            </DialogContent>

            <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button sx={nBtn} onClick={() => MediaUpdate(viewObj?._id, 'Rejected')} disabled={isLoading || !rjtRmk}>{isLoading ? <CircularProgress size={24} /> : 'Reject'}</Button>
            </DialogActions>
        </> : <>
            <Close sx={trClose} onClick={() => { setmediaModal(false); setviewObj(mObj); setrjtModal(false); }} />

            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', mt: '10px', mb: '5px', borderBottom: '1px solid #b5651d' }}>
                <Typography variant='h6'>{viewObj?.media_title}</Typography>
                <Typography sx={{ color: 'GrayText' }}>By <b>{uList.find((u) => u._id === viewObj?.media_createdby)?.full_name}</b> on <b>{FormatDT(viewObj?.created_at)}</b></Typography>
            </DialogTitle>

            <DialogContent><Card><Typography dangerouslySetInnerHTML={{ __html: decodeURIComponent(atob(viewObj?.media_summary)) }} /></Card></DialogContent>

            {ti === '1' && <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', mt: 1, gap: '5px' }}>
                <Button hidden={PerChk('REJECT_MEDIA')} sx={nBtn} onClick={() => setrjtModal(true)}>Reject</Button>
                <Button hidden={PerChk('APPROVE_MEDIA')} sx={pBtn} disabled={isLoading} onClick={() => MediaUpdate(viewObj?._id, 'Published')} >{isLoading ? <CircularProgress size={24} /> : 'Approve'}</Button>
            </DialogActions>}
        </>}</Dialog>
    </>)
}