import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import {
    Card,
    Box, Container, Typography, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Paper, MenuItem,
    FormControl, Snackbar, Alert,
    Select, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Toolbar, Tab, Tabs, useMediaQuery, TextField, Link, Divider
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { userList, phqAnsList } from '../../../services/UserService';
import { FormatDT, setTimeoutsecs, pBtn, nBtn, TPA, setAutoHideDurationTimeoutsecs, PerChk } from '../../../common/Common';
import { AddExp, ExpDetailslist, archiveExp, UnarchiveExp } from '../../../services/Experience';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';


export const mainTheme = createTheme({
    palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
    components: {
        MuiButton: { styleOverrides: { root: { borderRadius: '15px', color: '#fff', backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' }, '&.Mui-disabled': { backgroundColor: '#d3d3d3', color: '#333' } }, icon: { color: '#fff', marginLeft: '7px' } } },

        MuiCard: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiContainer: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        // MuiDialog: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiTextField: { styleOverrides: { root: { marginBottom: '5px' } }, defaultProps: { fullWidth: true } },
        MuiFormHelperText: { styleOverrides: { root: { backgroundColor: '#fcf2e7' } }, defaultProps: { fullWidth: true } },
        MuiInputBase: { styleOverrides: { root: { backgroundColor: '#ffffff' } } },
        MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
        MuiLink: { styleOverrides: { root: { fontWeight: 'bold', color: '#b5651d', cursor: 'pointer' } }, defaultProps: { underline: 'hover' } }
    }
})

export const pdfTheme = createTheme({
    palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
    components: {
        MuiButton: { styleOverrides: { root: { borderRadius: '15px', color: '#fff', backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' }, '&.Mui-disabled': { backgroundColor: '#d3d3d3', color: '#333' } }, icon: { color: '#fff', marginLeft: '7px' } } },
        MuiCard: { styleOverrides: { root: { borderRadius: '15px', padding: '15px' } } },
        MuiContainer: { styleOverrides: { root: { borderRadius: '15px', padding: '15px', marginTop: "20px" } } },
        MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
    }
})

const tabTheme = createTheme({
    palette: { primary: { main: '#000000' }, secondary: { main: '#ffffff' } },
    components: { MuiTab: { styleOverrides: { root: { textTransform: 'none', fontSize: '95%', fontWeight: 'bold', color: '#a5644e', '&:hover': { color: '#000000' } } } } }
})


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export default function CustomPaginationActionsTable() {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [expList, setExpList] = useState([]);
    const [userData, setUserData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [selectedRow, setSelectedRow] = useState(null);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [fullContent, setFullContent] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [archiveConf, setArchiveConf] = useState(false);
    const [unArchiveConf, setUnarchiveConf] = useState(false);
    const [archiveAlert, setArchiveAlert] = useState(false);
    const [archiveFailed, setArchiveFailed] = useState('');
    const [unArchiveAlert, setUnarchiveAlert] = useState(false);
    const [unArchiveFailed, setUnarchiveFailed] = useState('');

    useEffect(() => {
        if (selectedRow) {
            const base64Decoded = atob(selectedRow.experience_summary);
            const decodedHTML = decodeURIComponent(base64Decoded);
            setFullContent(decodedHTML);
        }
    }, [selectedRow]);

    const handleRowClick = (row) => {
        setSelectedRow(row);
        setExpanded(true);
    };

    const handleArchiveRowClick = async (row) => {
        setArchiveConf(true);
        setExpanded(false);
        setSelectedRow(row);
    };

    const handleUnarchiveRowClick = async (row) => {
        setUnarchiveConf(true);
        setExpanded(false);
        setSelectedRow(row);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - expList.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeStatusFilter = (event) => {
        setStatusFilter(event.target.value);
        setPage(0); // Reset page when filter changes
    };
    const ExpDataList = async () => {
        try {
            let resp = await ExpDetailslist();
            const filteredList = resp.result;
            setExpList(filteredList.reverse())
        } catch (error) {
            console.log(error)
        }
    };

    const handleArchiveConf = async () => {
        setLoading(true);
        try {
            let resp = await archiveExp(selectedRow._id);
            setArchiveAlert(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setArchiveConf(false);
                    userDatalist();
                    ExpDataList();
                    setArchiveAlert(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setArchiveFailed(error.response.data.message);
                setArchiveAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setArchiveFailed('Archive failed');
                setArchiveAlert(true);
            } setTimeout(() => {
                setLoading(false);
                // setArchiveConf(false);
                setArchiveAlert(false);
            }, setAutoHideDurationTimeoutsecs);
        }

    };
    const handleUnArchiveConf = async () => {
        setLoading(true);
        try {
            let resp = await UnarchiveExp(selectedRow._id);
            setUnarchiveAlert(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setUnarchiveConf(false);
                    userDatalist();
                    ExpDataList();
                    setUnarchiveAlert(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setUnarchiveFailed(error.response.data.message);
                setUnarchiveAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setUnarchiveFailed('Unarchive failed');
                setUnarchiveAlert(true);
            } setTimeout(() => {
                setLoading(false);
                // setUnarchiveConf(false);
                setUnarchiveAlert(false);
            }, setAutoHideDurationTimeoutsecs);
        }
    };
    const userDatalist = async () => {
        try {
            let resp = await userList();
            setUserData(resp.result);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            await userDatalist();
            await ExpDataList();
        };

        if (isMounted) {
            fetchData();
        }

        return () => {
            isMounted = false;
        };
    }, []);

    const filteredUserData = userData.filter(user => {
        return user.full_name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    const filteredData = expList.filter(expData => {
        const user = userData.find(user => user._id === expData.experience_createdby);
        const postedDate = FormatDT(expData.created_at).toLowerCase();
        const searchQueryLower = searchQuery.toLowerCase();

        const matchesSearchQuery = Object.values(expData).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        });
        const matchesUserData = filteredUserData.some(user => user._id === expData.experience_createdby);
        const matchesPostedDate = postedDate.includes(searchQueryLower);
        const statusMatches = statusFilter === 'All' || expData.experience_status === statusFilter;

        return (matchesSearchQuery || matchesPostedDate || matchesUserData) && statusMatches;
    });
    const nonMobileStyles = {
        flexGrow: 1,
        p: 3,
    };

    const getUserExpObj = (id) => {
        let obj = userData.filter(x => x._id == id)[0];
        return obj;
    }


    return (<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container component="main" sx={{ width: '80vw' }}>
            {expList.length > 0 && <>
                <Box sx={{ mb: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                        <FormControl sx={{ minWidth: '125px' }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                value={statusFilter}
                                onChange={handleChangeStatusFilter}
                                label="Status"
                            >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="Published">Published</MenuItem>
                                <MenuItem value="Pending">Pending</MenuItem>
                                <MenuItem value="Rejected">Rejected</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: '130px' }}>
                            <TextField
                                label="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </FormControl>
                    </Box >
                </Box></>
            }
            {filteredData.length > 0 ?
                < Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table>
                            <TableHead sx={{ bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }}>
                                <TableRow sx={{ alignItems: 'center', bgcolor: '#a5644e' }}>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Title</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Posted By</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Posted On</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>Status</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>Visible</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>isArchived</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold', width: '2%' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filteredData
                                ).map((row) => (
                                    <TableRow key={row._id} onClick={() => handleRowClick(row)}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#fcf2e7',
                                            },
                                        }}>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {row.experience_title}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {getUserExpObj(row.experience_createdby).full_name}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {FormatDT(row.created_at)}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textTransform: 'none', textAlign: 'center',
                                                color:
                                                    row.experience_status === 'Published'
                                                        ? 'green'
                                                        : row.experience_status === 'Rejected'
                                                            ? 'red'
                                                            : row.experience_status === 'Pending' ? 'darkblue'
                                                                : '#d3d3d3',
                                            }}
                                            component="th"
                                            scope="row"
                                        >
                                            {statusFilter === 'All' || row.experience_status === statusFilter ? row.experience_status : ''}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none', textAlign: 'center' }} component="th" scope="row">
                                            {row.visibility}
                                        </TableCell>
                                        {row.archive ? <TableCell sx={{ textTransform: 'none', textAlign: 'center' }} component="th" scope="row">
                                            Yes
                                        </TableCell> : <TableCell sx={{ textTransform: 'none', textAlign: 'center' }} component="th" scope="row">
                                            No
                                        </TableCell>}

                                        {row.experience_status === 'Published' ?
                                            row.archive ?
                                                <TableCell>
                                                    <Tooltip hidden={PerChk('UNARCHIVE_EXPERIENCE')} title="Unarchive">
                                                        <UnarchiveIcon sx={{ color: '#0c0' }} onClick={(e) => { e.stopPropagation(); handleUnarchiveRowClick(row) }}></UnarchiveIcon>
                                                    </Tooltip>
                                                </TableCell>
                                                :
                                                <TableCell>
                                                    <Tooltip hidden={PerChk('ARCHIVE_EXPERIENCE')} title="Archive">
                                                        <ArchiveIcon sx={{ color: '#c00' }} onClick={(e) => { e.stopPropagation(); handleArchiveRowClick(row) }}></ArchiveIcon>
                                                    </Tooltip>
                                                </TableCell> : <TableCell>
                                                <Tooltip hidden={PerChk('ARCHIVE_EXPERIENCE')} title="Archive">
                                                    <ArchiveIcon sx={{ color: '#d3d3d3', pointerEvents: 'none' }} onClick={(e) => { e.stopPropagation(); }}></ArchiveIcon>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>

                            <TableFooter><TableRow><TablePagination count={filteredData.length}
                                page={page} rowsPerPage={rowsPerPage}
                                sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                                ActionsComponent={TPA} /></TableRow></TableFooter>
                        </Table>
                    </TableContainer></Paper> : <Typography variant='h6' sx={{ textAlign: 'center' }}>No records</Typography>}
        </Container>
        {selectedRow &&
            <Dialog open={archiveConf} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Confirmation</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setArchiveConf(false)} />
                </DialogTitle>
                <DialogContent>
                    <Typography variant='body1'>Are you sure you want to archive this post?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={loading}
                        variant="contained"
                        sx={nBtn} onClick={handleArchiveConf}>Archive{loading ? <CircularProgress /> : null}</Button>
                </DialogActions>
                {archiveFailed ? <Snackbar
                    open={archiveAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {archiveFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={archiveAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Post archived
                    </Alert>
                </Snackbar>}
            </Dialog>
        }
        {selectedRow &&
            <Dialog open={unArchiveConf} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Confirmation</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setUnarchiveConf(false)} />
                </DialogTitle>
                <DialogContent>
                    <Typography variant='body1'>Are you sure you want to unarchive this post?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={loading}
                        variant="contained"
                        sx={pBtn} onClick={handleUnArchiveConf}>Unarchive{loading ? <CircularProgress /> : null}</Button>
                </DialogActions>
                {unArchiveFailed ? <Snackbar
                    open={unArchiveAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {unArchiveFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={unArchiveAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Unarchived post
                    </Alert>
                </Snackbar>}
            </Dialog>
        }
        {selectedRow &&
            <Dialog fullWidth maxWidth='lg' open={expanded}  >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow.experience_title}</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setExpanded(false)} />
                </DialogTitle>
                <DialogContent>
                    <Card sx={{ marginTop: '10px' }}>
                        <Typography className="ql-editor" dangerouslySetInnerHTML={{ __html: fullContent }}></Typography>
                    </Card>
                </DialogContent>
            </Dialog>
        }
    </Box>
    );
}









