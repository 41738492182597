import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import {
    Card,
    Box, Container, Typography, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Paper, MenuItem,
    FormControl, Snackbar, Alert,
    Select, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Toolbar, Tab, Tabs, useMediaQuery, TextField, Link, Divider
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { userList } from '../../services/UserService';
import { setTimeoutsecs, pBtn, TPA } from '../../common/Common';
import { AddExp, ExpDetailslist, UpdateExp, EditExp } from '../../services/Experience';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import '../../../public/styles/client.css';
import { mainTheme } from '../../core/TabsCore';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export default function RejectedList() {
    const [page, setPage] = React.useState(0);
    const [expList, setExpList] = useState([]);
    const [userData, setUserData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [fullContent, setFullContent] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitAlert, setSubmitAlert] = useState(false);
    const [submitFailed, setSubmitFailed] = useState('');
    const [valueData, setValue] = useState('');
    const visibilitydata = ['Members', 'Public'];
    const quill = useRef();
    const userId = sessionStorage.getItem('userId');
    const [title, setTitle] = useState('');
    const [visibility, setVisibility] = useState('');
    const disableaddSubmitbutton = !visibilitydata || !title || !valueData;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (selectedRow) {
            const base64Decoded = atob(selectedRow.experience_summary);
            const decodedHTML = decodeURIComponent(base64Decoded);
            setFullContent(decodedHTML);
            setValue(decodedHTML);
            setTitle(selectedRow.experience_title);
            setVisibility(selectedRow.visibility);
        }
    }, [selectedRow]);

    const handleRowClick = (row) => {
        setSelectedRow(row);
        setExpanded(true);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - expList.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
    ];

    const handleSubmit = async () => {
        const encodedContent = encodeURIComponent(valueData);
        const base64EncodedContent = btoa(encodedContent);
        setLoading(true);
        try {
            let data = {
                experience_title: title,
                experience_summary: base64EncodedContent,
                experience_status: 'Pending',
                experience_updatedby: userId,
                visibility: visibility
            }
            let resp = await EditExp(data, selectedRow._id);
            setSubmitAlert(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setOpen(false);
                    userDatalist();
                    ExpDataList();
                    setSubmitAlert(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setSubmitFailed(error.response.data.message);
                setSubmitAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setSubmitFailed('Experience update failed');
                setSubmitAlert(true);
            } setTimeout(() => {
                setLoading(false);
                setOpen(false);
                userDatalist();
                ExpDataList();
                setSubmitAlert(false);
            }, setTimeoutsecs);
        }
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                ],
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        []
    );


    const handleEditRowClick = (row) => {
        setSelectedRow(row);
        setOpen(true);
    }

    const ExpDataList = async () => {
        try {
            let resp = await ExpDetailslist();
            const filteredList = resp.result.filter(x => x.experience_status == 'Rejected' && x.experience_createdby == userId);
            setExpList(filteredList.reverse())
        } catch (error) {
            console.log(error)
        }
    };

    const userDatalist = async () => {
        try {
            let resp = await userList();
            setUserData(resp.result);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            await userDatalist();
            await ExpDataList();
        };

        if (isMounted) {
            fetchData();
        }

        return () => {
            isMounted = false;
        };
    }, []);

    const filteredData = expList.filter(user => {
        const matchesSearchQuery = Object.values(user).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        });


        return matchesSearchQuery;
    });


    const getUserExpObj = (id) => {
        let obj = userData.filter(x => x._id == id)[0];
        return obj;
    }


    return (<ThemeProvider theme={mainTheme}><Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container component="main" sx={{ width: '80vw' }}>

            <Box sx={{ mb: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                    <FormControl sx={{ width: '130px' }}>
                        <TextField
                            label="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            variant="outlined"
                            size="small"
                        />
                    </FormControl>
                </Box >

            </Box>
            {filteredData.length > 0 ?
                < Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table>
                            <TableHead sx={{ bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }}>
                                <TableRow sx={{ alignItems: 'center' }}>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Title</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Visible</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Remarks</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold', width: '2%' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filteredData
                                ).map((row) => (
                                    <TableRow key={row._id} onClick={() => handleRowClick(row)}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#fcf2e7',
                                            },
                                        }}>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {row.experience_title}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textTransform: 'none',
                                                color: 'red'
                                            }}
                                            component="th"
                                            scope="row"
                                        >{row.experience_status}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {row.visibility}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {row.remarks}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Edit">
                                                <EditIcon sx={{ color: '#b5651d' }} onClick={(e) => { e.stopPropagation(); handleEditRowClick(row) }}></EditIcon>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={expList.length}
                                        page={page} rowsPerPage={rowsPerPage}
                                        sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                        onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                                        ActionsComponent={TPA}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer> </Paper> : <Typography variant='h6' sx={{ textAlign: 'center' }}>No records</Typography>}
        </Container>
        {selectedRow &&
            <Dialog fullWidth maxWidth='lg' open={open} >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Share Your Experience</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setOpen(false)} />
                </DialogTitle>
                <DialogContent>
                    <Container>
                        <Box component={Grid} container spacing={2} sx={{ flexGrow: 1, p: 3 }} >
                            <Grid item sm={6}><TextField required label="Title" value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            /></Grid>
                            <Grid item sm={6}><TextField select required label="Visibility" value={visibility}
                                onChange={(e) => setVisibility(e.target.value)}>
                                {visibilitydata.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                            </Grid>
                        </Box>
                        <ReactQuill
                            ref={(el) => (quill.current = el)}
                            className="ql-container ql-snow"
                            value={valueData}
                            formats={formats}
                            modules={modules}
                            onChange={(content) => setValue(content)} />
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={disableaddSubmitbutton || loading}
                        variant="contained"
                        sx={pBtn} onClick={handleSubmit}>Save{loading ? <CircularProgress /> : null}</Button>
                </DialogActions>
                {submitFailed ? <Snackbar
                    open={submitAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {submitFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={submitAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Experience updated
                    </Alert>
                </Snackbar>}

            </Dialog>}

        {selectedRow &&
            <Dialog fullWidth maxWidth='lg' open={expanded}  >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow.experience_title}</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setExpanded(false)} />
                </DialogTitle>
                <DialogContent>
                    <Card sx={{ marginTop: '10px' }}>
                        <Typography className="ql-editor" dangerouslySetInnerHTML={{ __html: fullContent }}></Typography>
                    </Card>
                </DialogContent>

            </Dialog>
        }
    </Box>
    </ThemeProvider>
    );
}









